@import url(https://fonts.googleapis.com/css?family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css?family=Karla&display=swap);
div {
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Karla';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Raleway';
}

.title {
  color: #fff;
}

.subTitle {
  color: rgb(219, 219, 219);
}

.subTitle2 {
  color: rgb(179, 179, 179);
}

.active-link {
  color: #44a076;
  font-weight: 400;
}

.not-active-link {
  color: #fff;
  font-weight: 400;
}

.active-link:hover {
  color: #44a076;
  font-weight: 400;
}

.not-active-link:hover {
  color: #44a076;
  font-weight: 400;
}

.mainButton {
  background-color: #44a076;
  color: #fff;
}

.subButton {
  background-color: #1e2a3a;
  color: #fff;
}

.mainButton:hover {
  background-color: #367c5d;
  color: #fff;
}

.subButton:hover {
  background-color: #27374d;
  color: #fff;
}
.social-link-btn {
  text-decoration: none;
  margin: 0px 10px;
}

.horizontal-line-css {
  background-color: #27374d;
}

.vl {
  border-left: 3px solid #367c5d;
  height: 50px;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}

.wrapper {
  padding-left: 300px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

#sidebar {
  margin-left: -300px;
  left: 300px;
  width: 300px;
  background: #1e2a3a;
  position: fixed;
  height: 100%;
  overflow-y: auto;
  z-index: 1000;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

#content {
  width: 100%;
}

#resume-left {
  text-align: right;
}

@media (max-width: 767px) {
  .wrapper {
    padding-left: 0;
  }

  #sidebar {
    left: 0;
  }

  #wrapper.active {
    position: relative;
    left: 250px;
  }

  #wrapper.active #sidebar {
    left: 250px;
    width: 250px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }

  #resume-left {
    text-align: left;
  }

  .social-link-btn {
    text-decoration: none;
  }
}

.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: #44a076;
  border-radius: 0 4px 0 32px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
}

.card_ux {
  display:flex;
  position: relative;
  background-image: url(/static/media/homepetsCover.8bdf65f1.jpeg);
  background-position: center;
  background-size: cover;
  border-radius: 4px;
  margin: 12px;
  height: 200px;
  overflow: hidden;
  text-align: center;
  align-items: center;
}

.card_fl{
  display:flex;
  position: relative;
  background-image: url(/static/media/findlocalcover.94c16b54.png);
  background-position: center;
  background-size: cover;
  border-radius: 4px;
  margin: 12px;
  height: 200px;
  overflow: hidden;
  text-align: center;
  align-items: center;
}

.card_ux h3 {
  align-items: center;
  color: #ffffff;
  font-weight: bold;
  margin: auto
}

.card_fl h3 {
  align-items: center;
  color: #ffffff;
  font-weight: bold;
  margin: auto
}

.card_ux:hover {
  -webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

.card_fl:hover {
  -webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

.card1 {
  display: block;
  position: relative;
  background-color: #1e2a3a;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}
.card1:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: #44a076;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: -webkit-transform 0.25s ease-out;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}
.card1:hover:before {
  -webkit-transform: scale(40);
  transform: scale(40);
}

.card1 p {
  color: rgba(255, 255, 255, 0.8);
}
.card1 h5 {
  color: #ffffff;
}

.card1:hover p {
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  color: rgba(255, 255, 255, 0.8);
}
.card1:hover h5 {
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  color: #ffffff;
}

.resume-card {
  background-color: #1e2a3a;
  border-radius: 4px;
  padding: 40px 30px;
  margin: 30px;
}

.cursor-style {
  color: #44a076;
}

.App {
  text-align: center;
  margin: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

